import React, {useState} from "react";

import Layout from "../views/shared/layout/layout";
import Hero from "../views/shared/hero/Hero";
import Tips from "../views/home/tips/Tips";
import Reviews from "../components/GMB/Reviews";
import WeTechniques from "../views/techniques/slider/WeTechniques";
import ServiceResume from "../views/shared/serviceResume/ServiceResume";
import Team from "../views/home/team/Team";
import { graphql } from "gatsby";
import { getTechniqueMapped } from "../contexts/techniques/infrastructure/techniqueSanityDataMapper";
import { getTrainingMapped } from "../contexts/training/infrastructure/trainingSanityDataMapper";
import { getAuthorMapped } from "../contexts/blog/infrastructure/authorSanityDataMapper";
import { getOtherServiceMapped } from "../contexts/othersServices/infrastructure/otherServiceSanityDataMapper";
import { SEO } from "../components/SEO/SEO";
import Banner from "../views/shared/banner/Banner";
import HomeMarkupSchema from "../components/SEO/HomeMarkupSchema";
import ModalBook from "../views/shared/modalForm/ModalBook";


const IndexPage = ({data}) => {
  // const techniques = [];
  const trainings = [];
  const otherServices = [];
  const authors = [];
  const services = [];

    const [has_modal, setHasModal] = useState(false);


    data.allSanityTechnique.nodes.forEach((_technique) => {
    // techniques.push(getTechniqueMapped(_technique));
    services.push(getTechniqueMapped(_technique));
  })
  data.allSanityTraining.nodes.forEach((_training) =>{
    trainings.push(getTrainingMapped(_training));
  })
  data.allSanityAuthor.nodes.forEach((_author)=>{
    authors.push(getAuthorMapped(_author));
  })
  data.allSanityOtherServices.nodes.forEach((_service)=>{
    otherServices.push(getOtherServiceMapped(_service));
    services.push(getOtherServiceMapped(_service));
  })



  const physiotherapy =  getTechniqueMapped(data.sanityTechnique);

  return (
    <>
    {has_modal && (
        <ModalBook title={"Reserva cita"} is_active={setHasModal} simplyBookingId={0} section={"OTHERS"}/>
    )}
    <Layout>
        <HomeMarkupSchema />
        <SEO
        title={"Obelion Fisioterapeutas | Centro fisioterapia en Zaragoza"}
        description={"Reserva tu cita en nuestro centro de Fisioterapia en Zaragoza. ✓ Fisioterapia de última generación ✓ Pilates motor control ✓ Obelion Training ¡Te esperamos!"}
        />
        <Hero title={physiotherapy.title} subtitle={physiotherapy.subtitle} photo={physiotherapy.background.asset.gatsbyImageData.images.fallback.src} photo_alt={physiotherapy.background_alt} color={"#A3BCC4"}>
            <button className={"a-button"} onClick={() => {setHasModal(true)}}>
                Pide cita ahora
            </button>
            <p className={"a-offer"}>{physiotherapy.heroPrice.info}</p>
        </Hero>
        <Tips />
        <Team authors={authors}/>
        <Reviews />
        <Banner title={"Vuelve a sentirte bien con Obelion"}  background={physiotherapy.background.asset.gatsbyImageData.images.fallback.src} >
            <button className={"a-button"} onClick={() => setHasModal(true)}>
              Pide cita ahora
            </button>
        </Banner>
        <WeTechniques services={services} title={"Alcanza tu mejor versión con nuestros servicios"} >
          <p>En Obelion somos referentes en fisioterapia avanzada, ponemos las últimas tecnologías, los mejores profesionales y las técnicas más punteras a tu disposición.<br/> Los mejores profesionales abarcan diferentes campos de la salud para ofrecerte una cobertura completa y multidisciplinar.</p>
        </WeTechniques>

        <ServiceResume trainings={trainings} otherServices={otherServices} />
    </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
    query HomeQuery{
        sanityTechnique(_id: {eq: "7c9ab6f4-3da6-45bd-964c-ae2fc3a775e1"}) {
            ...Technique
            priceHero {
                info
                price
            }
        }
        allSanityTraining{
            nodes {
                ...Training
            }
        }
        allSanityTechnique (filter: {_id: {ne: "7c9ab6f4-3da6-45bd-964c-ae2fc3a775e1"}}){
            nodes {
                ...Technique
            }
        }
        allSanityAuthor(sort: {fields: position, order: ASC}){
            nodes{
                ...Author
            }
        }
        allSanityOtherServices {
            nodes{
                ...OtherService
            }
        }
        site {
            siteMetadata {
                projectId
                dataset
            }
        }
    }
`;